@import '../../../../node_modules/react-select/dist/react-select.css';
@import '../../../stylesheets/variables.postcss';
@import '../../../stylesheets/utils/mixins.postcss';

.Select-menu-outer {
  border: 1px solid $mediumDarkGrey;
}

.Select-menu-outer .Select-menu {
  max-height: 105px;

  @mixin above $medium {
    max-height: 200px;
  }
}

.Select-option {
  padding: 7px 10px;

  &.is-focused {
    background-color: #d4edff;
  }

  &.is-selected {
    background-color: #e9f6ff;
  }
}

.Select-value {
  padding: 0.5em 0.75em;
}

.Select-placeholder {
  padding: 0.5em 0.75em;
}