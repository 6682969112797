@import '../../../../stylesheets/utils/mixins.postcss';
@import '../../../../stylesheets/variables.postcss';

$heightOffset: 108px;

@keyframes pulsate {
  0% {opacity: 0.25;}
  50% {opacity: 1.0;}
  100% {opacity: 0.25;}
}

.MapWithList {
  position: relative;
  background: white;
  overflow: hidden;
  height: calc(100vh - $heightOffset);

  h1 {
    font-size: 1.5rem;
    line-height: 1.1;
    letter-spacing: 1.357px;
  }

  h1,
  h4 {
    margin: 0.5rem 0.5rem 1rem;
  }

  @mixin above $map-breakpoint {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    height: unset;
    overflow: unset;
  }
}

.MapWithList_modeSelect {
  background: $lightGrey;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 200;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  text-align: center;
  padding: 0.75rem 0 0.65rem 0;

  @mixin above $map-breakpoint {
    display: none;
  }
}

.MapWithList_map {
  position: absolute;
  top: 0px;
  left: 0;
  height: calc(100vh - $heightOffset);
  transform: translateX(100%);
  transition: height $hover-speed, transform 450ms ease-in-out-quart, flex-basis 450ms ease-in-out-quart;
  width: 100%;

  @mixin above $map-breakpoint {
    transform: none;
    display: flex;
    height: calc(100vh - 128px);
    position: sticky;
    top: 128px;
    left: auto;
    flex: 0 1 100%;
    z-index: 8;
    overflow: hidden;
  }
}

.MapWithList_sidebar {
  display: none;
  padding: 3rem 3rem 3rem 1rem;
  transition: transform 450ms ease-in-out-quart;

  :global(.Sidebar) {
    position: static;
    top: 0;
  }

  @mixin above $map-breakpoint {
    display: block;
    transform: translateX(0);
  }
}

.MapWithList_list {
  background: white;
  padding-top: 0.1px;
  padding-bottom: 0.75rem;
  transition: transform 450ms ease-in-out-quart, flex-basis 450ms ease-in-out-quart;

  @mixin below $map-breakpoint {
    margin-bottom: 50px;
    padding: 0 .5rem;
  }

  @mixin above $map-breakpoint {
    min-height: calc(100vh - 2 * $nav-height);
    transform: none;
    display: block;
    flex: 0 0 calc(100% - 424px);
    z-index: 10;
    padding: 0.5rem 1rem;
    margin: 0;
    background: white;
    list-style: none;
    height: 100%;
  }
}

.MapWithList_innerList {
  :global(.u-grid-row) {
    margin: 0 !important;
  }

  @mixin above $map-breakpoint {
    overflow-y: hidden;
    height: auto;
  }
}

.MapWithList__filtersOpen {
  .MapWithList_map {
    height: calc(100vh - 2 * $nav-height - $heightOffset);
  }
}

.MapWithList__list {
  height: auto;
}

.MapWithList__map {
  .MapWithList_map {
    transform: translateX(0);
  }

  .MapWithList_list {
    transform: translateX(-100%);
  }

  @mixin above $map-breakpoint {
    .MapWithList_map {
      flex-basis: 40%;
    }

    .MapWithList_list {
      flex-basis: 60%;
      transform: none;
    }

    :global(.MapWithList-map-inner) {
      transform: translateX(0);
    }

    .MapWithList_sidebar {
      transform: translateX(-100%);
    }
  }
}

.ModeButton {
  font-weight: 500;
  border-radius: 0;
  border: 0;
  background: none;
  color: $darkGrey;
  font-size: 0.95rem;
  margin-right: 0.5rem;
  line-height: 1;
  -webkit-appearance: none;

  &.ModeButton__active {
    color: $blue;
  }

  &:active, &:focus {
    outline: none;
  }

  span {
    vertical-align: middle
  }

  svg {
    display: inline-block;
    margin-left: 0.25rem;
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }

  &:last-child {
    margin-right: 0;
  }

}

.SecondaryHeader__MapFilters {
  height: unset !important;
}
